import { Headline, HeadlineProps } from '@almbrand/headline';

export interface HeadlineComponentProps extends LocalId {
	title: string;
	size: HeadlineProps['size'];
	heading: HeadlineProps['variant'];
	contentId: number;
	bmetricId?: string;
}

export const HeadlineComponent: React.FC<HeadlineComponentProps> = ({
	title,
	size,
	heading,
	contentId,
	useReadline,
	readlineId,
	bmetricId,
}) => {
	return (
		<Headline
			id={useReadline && readlineId ? readlineId : contentId}
			className={bmetricId}
			size={size}
			title={title}
			variant={heading}
		/>
	);
};
