import { Image, ImageProps } from '@almbrand/image';
import React from 'react';

interface MediaComponentProps extends LocalId {
	image: {
		url: string;
		type: string;
		properties: {
			height: number;
			width: number;
			contentType: string;
			contentId: number;
			imageFocalPoint?: {
				x: string;
				y: string;
			};
		};
	};
	imageShape: 'default' | 'round' | 'pill';
	ratio: string;
	fullscreen: boolean;
	useReadline: boolean;
	contentId?: string | number;
	bmetricId?: string;
}

export const MediaComponent: React.FC<MediaComponentProps> = ({
	image,
	imageShape,
	ratio,
	fullscreen,
	useReadline,
	contentId,
	readlineId,
	bmetricId,
}) => {
	const { url, properties } = image;
	const { imageFocalPoint } = properties;

	const altText = `Media of type ${image.type}`;

	// Map CMS content to ImageProps
	const imageProps: ImageProps = {
		id: useReadline && readlineId ? readlineId : contentId,
		className: bmetricId,
		imageSrc: url,
		altText: altText,
		ratio: ratio,
		imageShape: imageShape,
		followContentHeight: fullscreen,
	};

	// Conditionally add focalPoint if it exists
	if (imageFocalPoint && imageFocalPoint.x && imageFocalPoint.y) {
		imageProps.imageFocalPoint = {
			x: imageFocalPoint.x,
			y: imageFocalPoint.y,
		};
	}

	return <Image {...imageProps} />;
};
