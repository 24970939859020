import { Container } from '@almbrand/container';
import { Grid } from '@almbrand/grid';
import classNames from 'classnames';
import { Component } from 'components/Component';
import './ContainerLayout.scss';
import { GridCellComponent } from './GridCellComponent';

export const ContainerLayout: React.FC<{ component: ContentApiModel }> = ({ component }) => {
	const { style, content } = component ?? {};
	const { innerBackgroundColor, gridStyle, marginBottom, marginTop } = style ?? {};
	const { useReadline, readlineId, contentId, contentType, isInvertedOnMobile, bmetricId } = content ?? {};

	if (contentType == 'ReadlineBlock') return <Component {...content} />;

	const getDefaultMargin = (value) => {
		return ['', 'default'].includes(value) ? 'spacing-r' : value;
	};

	return (
		<Container
			desktopWidth="fullWidth"
			tabletWidth="fullWidth"
			mobileWidth="fullWidth"
			backgroundColor={innerBackgroundColor?.cssClass}
			id={useReadline && readlineId ? readlineId : contentId}
			className={classNames(`pb-${getDefaultMargin(marginBottom)}`, `pt-${getDefaultMargin(marginTop)}`)}
		>
			<Container
				desktopWidth="1600"
				tabletWidth="1280"
				mobileWidth="744"
				className={contentType === 'DefaultContainerBlock' && bmetricId}
			>
				<Grid
					container={gridStyle === 'fullscreen'}
					className={classNames(
						contentType === 'DefaultContainerBlock' && isInvertedOnMobile && 'revers-order',
					)}
				>
					{contentType === 'DefaultContainerBlock' ? (
						content?.mainArea?.items?.map((subItem, subIndex) => {
							return <GridCellComponent component={subItem} key={subIndex} applySpacings />;
						})
					) : (
						<GridCellComponent component={component} applySpacings={false} />
					)}
				</Grid>
			</Container>
		</Container>
	);
};
