import { pushToDataLayer } from '@almbrand/trackinghooks';
import $ from 'jquery'; // Import jQuery into your React component
import React, { useEffect, useState } from 'react';

interface FormRendererProps {
	header?: string;
	backgroundColor?: {
		cssClass?: string;
		colorHex?: string;
		colorComplexion?: string;
	};
	paddingAll?: string;
	useReadline?: boolean;
	template?: string;
	allowAnonymousSubmission?: boolean;
	allowMultipleSubmission?: boolean;
	showNavigationBar?: boolean;
	contentType?: string;
	contentId?: number;
	assets?: {
		OriginalJquery?: string;
		Jquery?: string;
		Prerequisite?: string;
		Css?: string;
		ViewModeJs?: string;
	};
	bmetricId?: string;
}

const FormRenderer: React.FC<FormRendererProps> = ({
	header = '',
	backgroundColor = { cssClass: '' },
	paddingAll = '',
	template = '',
	showNavigationBar = false,
	contentId = 0,
	assets = {},
	bmetricId,
	// contentType = '',
	// useReadline = false,
	// allowAnonymousSubmission = false,
	// allowMultipleSubmission = false,
}) => {
	const [clickedFields, setClickedFields] = useState(new Set());

	const injectJqueryScripts = () => {
		if (assets.OriginalJquery) {
			$.globalEval(assets.OriginalJquery);
		}
		if (assets.Jquery) {
			$.globalEval(assets.Jquery);
		}
	};

	const injectCss = () => {
		if (assets.Css) {
			const styleElement = document.createElement('style');
			styleElement.type = 'text/css';
			styleElement.innerHTML = assets.Css;
			document.head.appendChild(styleElement);
		}
	};

	const initializeForm = () => {
		const formContainer = document.getElementById(`form-container-${contentId}`);
		if (formContainer) {
			$(formContainer).html(template);

			if (assets.Prerequisite) {
				$.globalEval(assets.Prerequisite);
			}

			if (assets.ViewModeJs) {
				(window as any).epi.EPiServer.Forms.__Initialized = undefined;
				$.globalEval(assets.ViewModeJs);
			}

			const inputFields = document.querySelectorAll('.FormTextbox__Input');
			inputFields.forEach((inputField) => {
				inputField.addEventListener('click', handleInputFieldClick, { once: true });
			});
		}
	};

	useEffect(() => {
		if (!template) {
			console.error('FormRenderer: Template is missing');
			return;
		}

		let intervalId;

		injectJqueryScripts();
		injectCss();
		initializeForm();

		const submitButton = document.querySelector('.FormSubmitButton');

		const handleSubmitButtonClick = () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
			intervalId = setInterval(() => {
				const element = document.querySelector('.Form__Success__Message');
				if (element) {
					console.log('Success message found!');
					pushToDataLayer({
						event: 'trackFormSubmit',
						formName: handleGtmHeader(header, 'missing title on form'),
						url: window.location.href,
					});
					clearInterval(intervalId);
				}
			}, 500);
		};

		submitButton?.addEventListener('click', handleSubmitButtonClick);

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
			submitButton?.removeEventListener('click', handleSubmitButtonClick);
		};
	}, []);

	const handleInputFieldClick = () => {
		const inputField = document.querySelector('.FormTextbox__Input');
		const startInputTracking = inputField && !clickedFields.has(inputField);

		if (startInputTracking) {
			setClickedFields((prevClickedFields) => {
				const newClickedFields = new Set(prevClickedFields);
				newClickedFields.add(inputField);
				return newClickedFields;
			});

			pushToDataLayer({
				event: 'trackFormStart',
				formName: handleGtmHeader(header, 'missing title on form'),
				url: window.location.href,
			});
		}
	};

	const handleGtmHeader = (richText: string, alternativeGtmHeader: string) => {
		const parser = new DOMParser();
		const parsedDocument = parser.parseFromString(richText, 'text/html');
		const h2Element = parsedDocument.querySelector('h2');
		const gtmRichTextHeader = h2Element ? h2Element.textContent : null;

		return gtmRichTextHeader ? gtmRichTextHeader : alternativeGtmHeader;
	};

	return (
		<div className={`Form__Container ${backgroundColor.cssClass || ''} padding-${paddingAll} ${bmetricId}`}>
			{showNavigationBar && <div className="Form__NavigationBar"></div>}
			<div id={`form-container-${contentId}`} className="Form__MainBody RichText"></div>
		</div>
	);
};

export default React.memo(FormRenderer);
