import { Countdown } from '@almbrand/countdown';
import { Hero } from '@almbrand/hero';
import { ctaGroupMargins, generateButtonArray } from 'utilities';

export interface HeroComponentProps extends LocalId {
	contentId: number;
	image?: Image;
	textArea: string;
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
	countdownTitle?: string;
	countdownEndDate?: string;
	countdownExpiredText?: string;
	theme?: 'light' | 'dark';
	bmetricId?: string;
}

// The HeroBanner component using directly passed props
export const HeroComponent: React.FC<HeroComponentProps> = ({
	contentId,
	image,
	textArea,
	buttons,
	useReadline,
	readlineId,
	ctaMarginTop,
	ctaMarginBottom,
	countdownTitle,
	countdownEndDate,
	countdownExpiredText,
	theme,
	bmetricId,
}) => {
	const { properties, url } = image ?? {};
	const { imageFocalPoint: fp, altText } = properties ?? {};

	const focusX = parseFloat(fp?.x) * 100;
	const focusY = parseFloat(fp?.y) * 100;

	return (
		<Hero
			id={useReadline && readlineId ? readlineId : contentId}
			className={bmetricId}
			image={{ imageSrc: url, objectPosition: `${focusX}% ${focusY}%`, altText: altText ?? 'Hero Block Image' }}
			content={textArea}
			ctaGroupProps={generateButtonArray(buttons, ctaGroupMargins(ctaMarginTop, ctaMarginBottom))}
		>
			{countdownEndDate && (
				<Countdown
					title={countdownTitle}
					endDate={countdownEndDate}
					expiredText={countdownExpiredText}
					themeColor={theme}
					className="pt-spacing-lg"
				/>
			)}
		</Hero>
	);
};
